import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Data
import data1 from "layouts/dashboard/components/Projects1/data1";
import down from "assets/images/myimg/down.svg";



function Projects1() {
  const { columns, rows } = data1();
  const [menu, setMenu] = useState(null);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );
  const div1Style ={
    "margin-top":'20px',
    "background-color":'#2C2D2F',
    "border-radius":'10px',
    width:'260px',
    height:'35px'
  }
  const span1Style ={
    "margin-left":'20px',
    "margin-top":"10px"
    
  }
  const span2Style ={
    "margin-left":'20px',
    "margin-top":"10px",
    "background-color":'black',
    "border-radius":'4px',
    
  }
  const downStyle ={
    width:'20px',
    "margin-left":'10px',
    
  }

 
  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h1" gutterBottom>
            AI SELECT
          </MDTypography>
          <MDBox display="flex" alignItems="center" lineHeight={0}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong>30 done</strong> this month
            </MDTypography>
            <img src={down} style={downStyle}></img>
          </MDBox>
        </MDBox>
        <MDBox color="text" px={2}>
          <h5>Calculated By LIUX AI</h5>
          <div style={div1Style}>
            <span style={span1Style}>5m</span>
            <span style={span1Style}>1h</span>
            <span style={span1Style}>6h</span>
            <span style={span2Style}>24h</span>
            <span style={span1Style}>7d</span>
          </div>
        </MDBox>
        {renderMenu}
        
      </MDBox>
      <MDBox>
        <DataTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
        />
      </MDBox>
      
    </Card>
  );
}

export default Projects1;
