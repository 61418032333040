// import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";


// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";
import { Root } from "Root";


ReactDOM.render(
  
  <BrowserRouter>
    <MaterialUIControllerProvider>

    <Root />
    
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
