import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";

// Images
import logoXD from "assets/images/small-logos/logo-xd.svg";
import logoAtlassian from "assets/images/small-logos/logo-atlassian.svg";
import logoSlack from "assets/images/small-logos/logo-slack.svg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import logoJira from "assets/images/small-logos/logo-jira.svg";
import logoInvesion from "assets/images/small-logos/logo-invision.svg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import btc from "assets/images/myimg/coin/btc.png"
import eth from "assets/images/myimg/coin/eth.png"
import bnb from "assets/images/myimg/coin/bnb.png"
import xrp from "assets/images/myimg/coin/xrp.png"
import ada from "assets/images/myimg/coin/ada.png"
import dog from "assets/images/myimg/coin/dog.png"
import matic from "assets/images/myimg/coin/matic.png"
import sol from "assets/images/myimg/coin/sol.png"
import dot from "assets/images/myimg/coin/dot.png"
import shib from "assets/images/myimg/coin/shib.png"
import usdt from "assets/images/myimg/coin/usdt.png"
import busd from "assets/images/myimg/coin/busd.png"

export default function data() {
  const avatars = (members) =>
    members.map(([image, name]) => (
      <Tooltip key={name} title={name} placeholder="bottom">
        <MDAvatar
          src={image}
          alt="name"
          size="xs"
          sx={{
            border: ({ borders: { borderWidth }, palette: { white } }) =>
              `${borderWidth[2]} solid ${white.main}`,
            cursor: "pointer",
            position: "relative",

            "&:not(:first-of-type)": {
              ml: -1.25,
            },

            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    ));

  const Company = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  return {
    columns: [
      { Header: "TOKEN", accessor: "companies", width: "45%", align: "left" },
      { Header: "PRICE", accessor: "members", width: "10%", align: "left" },
      { Header: "QUANTITY", accessor: "budget", align: "center" },
      { Header: "VALUE", accessor: "completion", align: "left" },
    ],

    rows: [
      {
        companies: <Company image={eth} name="ETH" />,
        members: (
          <MDBox display="flex" py={1}>
            $1,637.77s
          </MDBox>
        ),
        budget: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            2.1
          </MDTypography>
        ),
        completion: (
          <MDBox width="8rem" textAlign="left">
            $3439.317
          </MDBox>
        )
      },
      {
        companies: <Company image={bnb} name="BNB" />,
        members: (
          <MDBox display="flex" py={1}>
            $322.19
          </MDBox>
        ),
        budget: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            6.51
          </MDTypography>
        ),
        completion: (
          <MDBox width="8rem" textAlign="left">
            $2162.556
          </MDBox>
        )
      },
      {
        companies: <Company image={dog} name="DOGE" />,
        members: (
          <MDBox display="flex" py={1}>
            $0.09105000
          </MDBox>
        ),
        budget: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            16200
          </MDTypography>
        ),
        completion: (
          <MDBox width="8rem" textAlign="left">
            $1474.011
          </MDBox>
        )
      },
      {
        companies: <Company image={matic} name="MATIC" />,
        members: (
          <MDBox display="flex" py={1}>
            $1.23
          </MDBox>
        ),
        budget: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
           346
          </MDTypography>
        ),
        completion: (
          <MDBox width="8rem" textAlign="left">
            $2834.942
            
          </MDBox>
        )
      },
      {
        companies: <Company image={sol} name="SOL" />,
        members: (
          <MDBox display="flex" py={1}>
            $23.20
          </MDBox>
        ),
        budget: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            452
          </MDTypography>
        ),
        completion: (
          <MDBox width="8rem" textAlign="left">
            $1324.442
          </MDBox>
        )
      },
      {
        companies: <Company image={busd} name="BUSD" />,
        members: (
          <MDBox display="flex" py={1}>
              $1
          </MDBox>
        ),
        budget: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            850.763
          </MDTypography>
        ),
        completion: (
          <MDBox width="8rem" textAlign="left">
            $850.763
          </MDBox>
        )
      },
      {
        companies: <Company image={usdt} name="USDT" />,
        members: (
          <MDBox display="flex" py={1}>
            $1
          </MDBox>
        ),
        budget: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            431.952
          </MDTypography>
        ),
        completion: (
          <MDBox width="8rem" textAlign="left">
            $441.952
          </MDBox>
        )
      },
      
     
    ],
  };
}
