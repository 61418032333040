import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";

// Images
import logoXD from "assets/images/small-logos/logo-xd.svg";
import logoAtlassian from "assets/images/small-logos/logo-atlassian.svg";
import logoSlack from "assets/images/small-logos/logo-slack.svg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import logoJira from "assets/images/small-logos/logo-jira.svg";
import logoInvesion from "assets/images/small-logos/logo-invision.svg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import btc from "assets/images/myimg/coin/btc.png"
import eth from "assets/images/myimg/coin/eth.png"
import bnb from "assets/images/myimg/coin/bnb.png"
import xrp from "assets/images/myimg/coin/xrp.png"
import ada from "assets/images/myimg/coin/ada.png"
import dog from "assets/images/myimg/coin/dog.png"
import matic from "assets/images/myimg/coin/matic.png"
import sol from "assets/images/myimg/coin/sol.png"
import dot from "assets/images/myimg/coin/dot.png"
import shib from "assets/images/myimg/coin/shib.png"

export default function data1() {
  const avatars = (members) =>
    members.map(([image, name]) => (
      <Tooltip key={name} title={name} placeholder="bottom">
        <MDAvatar
          src={image}
          alt="name"
          size="xs"
          sx={{
            border: ({ borders: { borderWidth }, palette: { white } }) =>
              `${borderWidth[2]} solid ${white.main}`,
            cursor: "pointer",
            position: "relative",

            "&:not(:first-of-type)": {
              ml: -1.25,
            },

            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    ));

  const Company = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  return {
    columns: [
      { Header: "NAME", accessor: "companies", width: "15%", align: "left" },
      { Header: "PRICE", accessor: "members", width: "10%", align: "left" },
      { Header: "AI SCORE", accessor: "budget", align: "center" },
      { Header: "24h VOLUEM", accessor: "completion", align: "center" },
      { Header: "MARKET", accessor: "completion1", align: "center" },
    ],

    rows: [
      {
        companies: <Company image={btc} name="BTC Bitcoin" />,
        members: (
          <MDBox display="flex" py={1}>
            $22,971.57
          </MDBox>
        ),
        budget: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            87
          </MDTypography>
        ),
        completion: (
          <MDBox width="8rem" textAlign="left">
            24,365.55M
          </MDBox>
        ),
        completion1: (
          <MDBox width="8rem" textAlign="left">
            $442.982.60M
          </MDBox>
        ),
      },
      {
        companies: <Company image={eth} name="ETH Ethereum" />,
        members: (
          <MDBox display="flex" py={1}>
            $1637.77
          </MDBox>
        ),
        budget: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            94
          </MDTypography>
        ),
        completion: (
          <MDBox width="8rem" textAlign="left">
            6,830.11M
          </MDBox>
        ),
        completion1: (
          <MDBox width="8rem" textAlign="left">
            $200,420.24M
          </MDBox>
        ),
      },
      {
        companies: <Company image={bnb} name="BNB BNB" />,
        members: (
          <MDBox display="flex" py={1}>
            $328.80
          </MDBox>
        ),
        budget: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            91
          </MDTypography>
        ),
        completion: (
          <MDBox width="8rem" textAlign="left">
            502.29M
          </MDBox>
        ),
        completion1: (
          <MDBox width="8rem" textAlign="left">
            $51.917.55M
          </MDBox>
        ),
      },
      {
        companies: <Company image={xrp} name="XRP Ripple" />,
        members: (
          <MDBox display="flex" py={1}>
            $0.39650000
          </MDBox>
        ),
        budget: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            74
          </MDTypography>
        ),
        completion: (
          <MDBox width="8rem" textAlign="left">
            923.28M
          </MDBox>
        ),
        completion1: (
          <MDBox width="8rem" textAlign="left">
            $20.141.83M
          </MDBox>
        ),
      },
      {
        companies: <Company image={ada} name="ADA Cardano" />,
        members: (
          <MDBox display="flex" py={1}>
            $0.38750000
          </MDBox>
        ),
        budget: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            71
          </MDTypography>
        ),
        completion: (
          <MDBox width="8rem" textAlign="left">
            307.61M
          </MDBox>
        ),
        completion1: (
          <MDBox width="8rem" textAlign="left">
            $13.411.24M
          </MDBox>
        ),
      },
      {
        companies: <Company image={dog} name="DOGE Dogecoin" />,
        members: (
          <MDBox display="flex" py={1}>
            $0.09105000
          </MDBox>
        ),
        budget: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            68
          </MDTypography>
        ),
        completion: (
          <MDBox width="8rem" textAlign="left">
            459.82M
          </MDBox>
        ),
        completion1: (
          <MDBox width="8rem" textAlign="left">
            $12,079.67M
          </MDBox>
        ),
      },
      {
        companies: <Company image={matic} name="MATIC Polygon" />,
        members: (
          <MDBox display="flex" py={1}>
            $1.23
          </MDBox>
        ),
        budget: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            72
          </MDTypography>
        ),
        completion: (
          <MDBox width="8rem" textAlign="left">
            487.93M
          </MDBox>
        ),
        completion1: (
          <MDBox width="8rem" textAlign="left">
            $10,743.21M
          </MDBox>
        ),
      },
      {
        companies: <Company image={sol} name="SOL Solana" />,
        members: (
          <MDBox display="flex" py={1}>
             $23.20
          </MDBox>
        ),
        budget: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
           85
          </MDTypography>
        ),
        completion: (
          <MDBox width="8rem" textAlign="left">
            440.46M
          </MDBox>
        ),
        completion1: (
          <MDBox width="8rem" textAlign="left">
            $8,658.53M
          </MDBox>
        ),
      },
      {
        companies: <Company image={dot} name="DOT Polkadot" />,
        members: (
          <MDBox display="flex" py={1}>
            $6.72
          </MDBox>
        ),
        budget: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            81
          </MDTypography>
        ),
        completion: (
          <MDBox width="8rem" textAlign="left">
            218.75M
          </MDBox>
        ),
        completion1: (
          <MDBox width="8rem" textAlign="left">
            $7,758.97M
          </MDBox>
        ),
      },
      {
        companies: <Company image={shib} name="SHIB SHIBA INU" />,
        members: (
          <MDBox display="flex" py={1}>
            0.00001405
          </MDBox>
        ),
        budget: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            74
          </MDTypography>
        ),
        completion: (
          <MDBox width="8rem" textAlign="left">
            461.13M
          </MDBox>
        ),
        completion1: (
          <MDBox width="8rem" textAlign="left">
            $7,714.33M
          </MDBox>
        ),
      },
     
    ],
  };
}
