import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Data
import data from "layouts/dashboard/components/Projects/data";
import down from "assets/images/myimg/down.svg";
import d1 from "assets/images/myimg/d1.svg";



function Projects1() {
  const { columns, rows } = data();
  const [menu, setMenu] = useState(null);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );
  // const div0Style = {
  //   position: 'relative',
  // }

  const div1Style = {
    position: 'relative',
    top: '50px',
    left: '-60px',
    "background-color": '#2C2D2F',
    "border-radius": '30px',
    width: '50px',
    height: '50px',

  }
  const span1Style = {
    "margin-left": '20px',
    "margin-top": "10px"

  }
  const span2Style = {
    "margin-left": '20px',
    "margin-top": "10px",
    "background-color": 'black',
    "border-radius": '4px',

  }
  const downStyle = {
    width: '20px',
    "margin-left": '10px',

  }

  const d1Style = {
    width: '50px',

  }


  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h1" gutterBottom>
            AI CHECK
          </MDTypography>
          <MDBox display="flex" alignItems="center" lineHeight={0}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong>Assets</strong> Overview
            </MDTypography>
            <img src={down} style={downStyle}></img>
          </MDBox>
        </MDBox>


        <MDBox color="text" px={2}>
          {/* <div> */}
          <div style={div1Style}>
            <img src={d1} style={d1Style}></img>
          </div>
          {/* </div> */}
          <h5>$8368.59</h5>
          <h5>Total Assetss</h5>
        </MDBox>
        {renderMenu}

      </MDBox>
      <MDBox>
        <DataTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
        />
      </MDBox>

    </Card>
  );
}

export default Projects1;
