import '@rainbow-me/rainbowkit/styles.css';
import {getDefaultWallets, RainbowKitProvider,darkTheme} from "@rainbow-me/rainbowkit";
import { configureChains, createClient, goerli, WagmiConfig } from 'wagmi';
import { mainnet, polygon, optimism, arbitrum, bsc, bscTestnet } from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';

import App from 'App';

const { chains, provider } = configureChains(
  [bsc],
  [
    publicProvider()
  ]
);
const { connectors } = getDefaultWallets({
  appName: 'Liux AI',
  chains
});
const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
})

export  const Root = () => {
    return (
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider chains={chains} showRecentTransactions={true} coolMode>
          <App />
        </RainbowKitProvider>
      </WagmiConfig>
    );
  };